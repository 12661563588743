import { useState } from "react";
import { AppointmentPayment } from "../api/api";

const AppointmentCard = ({ appointment }) => {
    const [payText, setPayText] = useState('Pay');
    const [payDisabled, setPayDisabled] = useState(false);

    const pay = async () => {
        setPayText('Redirecting...');
        setPayDisabled(true);
        var response = await AppointmentPayment({ params: { appId: appointment._id, policy_name: 'xyz' } });
        window.location.href = response.short_url;
    }

    return (
        <div className='card'>
            <div className="card-body d-flex flex-wrap align-items-stretch">
                <div>
                    <div className="list-group list-group-flush">
                        <div className="p-2">
                            <h4 className="mb-0">{appointment.doctorId.name}</h4>
                            <span className="text-body-secondary">{appointment.doctorId.specialization}</span>
                        </div>
                        <div className="p-2">
                            <h4 className="mb-0">{appointment.patientName} {appointment?.patientAge??""} {appointment?.patientGender??" "[0]}</h4>
                            <span className="text-body-secondary">Patient</span>
                        </div>
                        <div className="p-2 d-flex gap-2">
                            <i className="bi bi-hospital"></i>
                            <div>
                                <div className="d-flex align-items-center gap-2">
                                    <h5 className="mb-0">{appointment.clinicId.name}</h5>
                                    <a class="icon-link icon-link-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" href={`${appointment.clinicId.map_link}`}>
                                        <span className="lh-1">Open in Map</span>
                                        <i className="bi bi-map lh-1"></i>
                                    </a>
                                </div>
                                <span className="text-body-secondary">{appointment.clinicId.address.locality}, {appointment.clinicId.address.city}, {appointment.clinicId.address.pincode}</span>
                            </div>
                        </div>
                        <div className="p-2 d-flex gap-2">
                            <i className="bi bi-clock-history"></i>
                            <div>
                                <h5 className="mb-0">{appointment.timeVisited}, {(new Date(Date.parse(appointment.date))).toDateString()}</h5>
                                <span className="text-body-secondary">Time Slot</span>
                            </div>
                        </div>
                        <div className="p-2 d-flex gap-2">
                            <i className="bi bi-arrow-clockwise"></i>
                            <div>
                                <h5 className="mb-0">Status: {appointment.status}</h5>
                                <small className="text-body-secondary">ID: {appointment._id}</small>
                            </div>
                        </div>
                        <div className="p-2">
                            {appointment.paymentStatus !== "paid" ? (
                                <button onClick={pay} className="btn btn-primary" disabled={payDisabled}>{payText}</button>
                            ) : (<div className="btn outline outline-0 px-0" disabled={true}>Payment Done</div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppointmentCard;