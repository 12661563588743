import React, { useState, useEffect } from 'react';
import { CreateDoctor, UpdateDoctor } from '../api/api';


const DoctorForm = ({ doctor = null, onSubmit = null }) => {
    const [submitText, setSubmitText] = useState('Save')
    const [submitDisabled, setSubmitDisabled] = useState(false)

    const [name, setName] = useState(doctor?.name ?? "");
    const [regNumber, setRegNumber] = useState(doctor?.regNumber ?? "");
    const [email, setEmail] = useState(doctor?.email ?? "");
    const [phone, setPhone] = useState(doctor?.phone ?? "");
    const [gender, setGender] = useState(doctor?.gender ?? "");
    const [experience, setExperience] = useState(doctor?.experience ?? "");
    const [fee, setFee] = useState(doctor?.fee ?? "");
    const [qualification, setQualification] = useState(doctor?.qualification ?? "");
    const [speciality, setSpeciality] = useState(doctor?.speciality ?? "");


    useEffect(() => {
        if (doctor?._id)
            setSubmitText("Save")
        else
            setSubmitText("Create")
    }, [doctor]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name.trim() && email.trim() && phone.trim() && gender.trim() && qualification.trim() && speciality.trim()) {
            setSubmitText('Saving...');
            setSubmitDisabled(true);
            try {
                if (doctor?._id)
                    await UpdateDoctor({
                        data: {
                            doctorId: doctor?._id,
                            name: name,
                            regNumber: regNumber,
                            email: email,
                            phone: phone,
                            gender: gender,
                            experience: experience,
                            fee: fee,
                            qualification: qualification,
                            speciality: speciality,
                        }
                    });
                else
                    await CreateDoctor({
                        data: {
                            name: name,
                            regNumber: regNumber,
                            email: email,
                            phone: phone,
                            gender: gender,
                            experience: experience,
                            fee: fee,
                            qualification: qualification,
                            speciality: speciality,
                        }
                    });
                if (onSubmit != null)
                    onSubmit();
            } catch (error) {
                console.error('Error Saving:', error.response ? error.response.data : error.message);
            }
            setSubmitText('Save');
            setSubmitDisabled(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{ minWidth: 300 + 'px' }}>
            <div className="d-flex flex-wrap gap-2">
                <div className="col form-floating mb-2">
                    <input
                        type="text"
                        id="doctor-name-input"
                        className="form-control"
                        value={name}
                        placeholder="Name"
                        required={true}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <label className="form-label">Name</label>
                </div>
                <div className="form-floating">
                    <select className="form-select" defaultValue={gender} id="doctor-gender-select" required={true} onChange={(e) => setGender(e.target.value)}>
                        <option value="" disabled>Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                    <label className="form-label">Gender</label>
                </div>
            </div>
            <div className="d-flex flex-wrap gap-2 mb-2">
                <div className="col-12 col-sm form-floating">
                    <input
                        type="email"
                        id="doctor-email-input"
                        className="form-control"
                        value={email}
                        placeholder="Email"
                        required={true}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <label className="form-label">Email</label>
                </div>
                <div className="col-12 col-sm form-floating">
                    <input
                        type="tel"
                        id="doctor-phone-input"
                        className="form-control"
                        value={phone}
                        placeholder="Phone"
                        required={true}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <label className="form-label">Phone</label>
                </div>
            </div>
            <div className="d-flex flex-wrap gap-2 mb-2">
                <div className="col-12 col-sm form-floating">
                    <input
                        type="text"
                        id="doctor-regNumber-input"
                        className="form-control"
                        value={regNumber}
                        placeholder="Registration Number"
                        required={true}
                        onChange={(e) => setRegNumber(e.target.value)}
                    />
                    <label className="form-label">Registration Number</label>
                </div>
                <div className="col-12 col-sm d-flex flex-wrap gap-2">
                    <div className="col form-floating">
                        <input
                            type="number"
                            id="doctor-experience-input"
                            className="form-control"
                            value={experience}
                            placeholder="Experience"
                            required={true}
                            onChange={(e) => setExperience(e.target.value)}
                        />
                        <label className="form-label">Experience</label>
                    </div>
                    <div className="col form-floating">
                        <input
                            type="number"
                            id="doctor-fee-input"
                            className="form-control"
                            value={fee}
                            placeholder="Fee"
                            required={true}
                            onChange={(e) => setFee(e.target.value)}
                        />
                        <label className="form-label">Fee</label>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-wrap gap-2 mb-2">
                <div className="col-12 col-sm form-floating">
                    <input
                        id="doctor-qualification-input"
                        className="form-control"
                        value={qualification}
                        placeholder="Qualification"
                        rows="10"
                        required={true}
                        onChange={(e) => setQualification(e.target.value)}
                    />
                    <label className="form-label">Qualification</label>
                </div>
                <div className="col-12 col-sm form-floating">
                    <input
                        id="doctor-speciality-input"
                        className="form-control"
                        value={speciality}
                        placeholder="Speciality"
                        rows="10"
                        required={true}
                        onChange={(e) => setSpeciality(e.target.value)}
                    />
                    <label className="form-label">Speciality</label>
                </div>
            </div>
            <button type="submit" className="btn btn-lg btn-primary w-100" disabled={submitDisabled}>{submitText}</button>
        </form>
    );
}

export default DoctorForm;