import React, { useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";
import { GetClinics } from '../api/api';
import ClinicCard from '../components/ClinicCard';
import ClinicForm from '../components/ClinicForm';
import Modal from '../components/Modal';

const AdminClinics = () => {
    const [clinics, setClinics] = useState([])
    const [newClinics, setNewClinics] = useState([])
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [shown, setShown] = useState(0);
    const [total, setTotal] = useState(0);

    const getClinics = useCallback(async () => {
        var response = await GetClinics({ data: { page: page } });
        if (page !== response.currentPage)
            setPage(response.currentPage);
        setNewClinics(response.results);
        setPages(response.totalPages);
        setTotal(response.totalResults);
    }, [page]);

    useEffect(() => {
        if (newClinics) {
            var totalClinics = []
            if (page === 1)
                totalClinics = newClinics;
            else
                totalClinics = clinics.concat(newClinics)
            setNewClinics(null);
            setClinics(totalClinics);
        }
        setShown(clinics.length);
    }, [clinics, newClinics, page]);

    useEffect(() => {
        getClinics();
        window.scrollTo(0, 0);
        document.title = 'DocInJob | Clinics';
    }, [getClinics]);

    return (
        <div className='d-flex flex-column p-3 p-sm-5 gap-2'>
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    <h3 className='mb-0'>Clinics</h3>
                    <div className='text-body-secondary'>Showing {shown} of {total} Clinics</div>
                </div>
                <Modal modalId="add-clinic" title="Add Clinic"
                    body={
                        <ClinicForm onSubmit={() => {
                            document.getElementById(`add-clinic-close`).click();
                            getClinics(1)
                        }} />
                    }
                    buttonClassName="btn btn-lg btn-outline-primary"
                    buttonContent={
                        <span className="d-flex align-items-center gap-2">
                            <i className="bi bi-plus-circle lh-1"></i>
                            <span className="lh-1">Add Clinic</span>
                        </span>
                    } />
            </div>
            <div className='d-flex flex-wrap gap-2'>
                {clinics.length ?
                    clinics.map((clinic, index) => (
                        <Link key={"clinic-" + index} to={`/admin/clinic/?id=${clinic._id}`}>
                            <ClinicCard key={`clinic-${index}`} clinic={clinic} />
                        </Link>
                    )) : (
                        <div>No clinics!</div>
                    )
                }
            </div>
            {page < pages ? (
                <button className="btn btn-primary w-100" onClick={() => setPage(page + 1)}>Load More</button>
            ) : (
                <></>
            )}
        </div>
    );
}

export default AdminClinics;