import TimingForm from './TimingForm';
import Modal from './Modal';
import { DeleteTiming } from '../api/api';


const Timings = ({ doctor, timings, clinic, onChange }) => {
    return (
        <div className='d-flex flex-wrap gap-2'>
            {timings.length ?
                timings.map((timing, index) => (
                    <div key={`timing-${index}`}>
                        <div className='input-group'>
                            <div className='input-group-text text-body border-primary'>
                                {timing.dayOfWeek}, {timing.startTime} - {timing.endTime}
                            </div>
                            <Modal modalId={"edit-timing-" + timing._id} title={"Edit Timing"}
                                modaldialogClassNames="modal-dialog-centered modal-dialog-sm"
                                body={
                                    <TimingForm clinic={clinic} doctor={doctor} timing={timing} onSubmit={() => {
                                        onChange();
                                        document.getElementById(`edit-timing-${timing._id}-close`).click();
                                    }} />
                                }
                                buttonClassName="btn btn-outline-primary"
                                buttonContent={
                                    <i className='bi bi-pen'></i>
                                } />
                            <Modal modalId={"delete-timing-" + timing._id} title={"Delete Timing"}
                                modaldialogClassNames="modal-dialog-centered modal-dialog-sm"
                                body={
                                    <div>
                                        <h4 className='mb-0'>{doctor.name} at {clinic.name}</h4>
                                        <span className='text-body-secondary'>{timing.dayOfWeek}, {timing.startTime} - {timing.endTime}</span>
                                    </div>
                                }
                                footer={
                                    <button className="btn btn-danger" onClick={async () => { 
                                        await DeleteTiming({ data: { _idDay: timing._id, doctorId: doctor._id, clinicId: clinic._id } });
                                        document.getElementById(`delete-timing-${timing._id}-close`).click();
                                        onChange();
                                    }}>Delete</button>
                                }
                                buttonClassName="btn btn-outline-danger"
                                buttonContent={
                                    <i className='bi bi-trash'></i>
                                } />
                        </div>
                    </div>
                )) : (
                    <div>No timings!</div>
                )
            }
        </div>
    );
}

export default Timings;