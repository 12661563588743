import SearchBar from './SearchBar';

const Banner = ({setCityIndex, cities = [], specialities=[], cityIndex = 0, image}) => {
    return (
        <div>
            <img className="w-100" style={{objectFit: 'cover', minHeight: 30 + 'vh'}} src={image} alt="Banner" />
            <div className="container col-xxl-8 px-4 py-5">
                <h5 className='display-5 text-primary fw-bold text-center mb-4'>Book an appointment with your doctor</h5>
                <SearchBar setCityIndex={setCityIndex} cities={cities} specialities={specialities} cityIndex={cityIndex} />
            </div>
        </div>
    );
}

export default Banner;