import { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from "react-router-dom";
import { GetClinic } from '../api/api';
import ClinicCard from '../components/ClinicCard';
import ClinicForm from '../components/ClinicForm';
import DoctorTimings from '../components/DoctorTimings';


const AdminClinic = () => {
    const [searchParams] = useSearchParams();
    const [clinic, setClinic] = useState(null);

    const getClinic = useCallback(async () => {
        setClinic(await GetClinic({ id: searchParams.get('id') }));
    }, [searchParams]);

    useEffect(() => {
        getClinic();
        window.scrollTo(0, 0);
        document.title = 'DocInJob | Clinic';
    }, [getClinic]);

    useEffect(() => {
        document.title = 'DocInJob | ' + clinic?.name?? 'Clinic';
    }, [clinic]);

    return (
        <div className='d-flex flex-column gap-2'>
            {clinic &&
                <div className='d-flex flex-column align-items-stretch gap-3 p-3 p-sm-5'>
                    <ClinicCard clinic={clinic} onChange={getClinic} form={<ClinicForm clinic={clinic} onSubmit={() => {
                        document.getElementById(`edit-clinic-${clinic._id}-close`).click();
                        getClinic();
                    }} />} />
                    <DoctorTimings clinic={clinic} onChange={getClinic} />
                </div>
            }
        </div>
    );
}

export default AdminClinic;