import React, { useState, useEffect, useCallback } from 'react';
import { GetDoctors, AddTiming, UpdateTiming } from '../api/api';


const TimingForm = ({ timing = null, doctor = null, clinic, onSubmit }) => {
    const [submitText, setSubmitText] = useState('Save')
    const [submitDisabled, setSubmitDisabled] = useState(false)

    const [docquery, setDocquery] = useState('');
    const [doctors, setDoctors] = useState(doctor ? [doctor] : []);
    const [selectedDoctor, setSelectedDoctor] = useState(doctor ?? null);

    const [dayOfWeek, setDayOfWeek] = useState(timing?.dayOfWeek ?? "");
    const [startTime, setStartTime] = useState(timing?.startTime ?? "");
    const [endTime, setEndTime] = useState(timing?.endTime ?? "");

    const getDoctors = useCallback(async () => {
        const response = await GetDoctors({ data: { regName: docquery } });
        setDoctors(response.results);
    }, [docquery]);

    useEffect(() => {
        if (timing?._id)
            setSubmitText("Save")
        else
            setSubmitText("Create")
        if (doctor == null && docquery.trim())
            getDoctors();
    }, [timing, doctor, docquery, getDoctors]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (dayOfWeek.trim() && startTime.trim() && endTime.trim()) {
            setSubmitText('Saving...');
            setSubmitDisabled(true);
            try {
                if (timing?._id)
                    await UpdateTiming({
                        data: {
                            _idDay: timing?._id,
                            doctorId: selectedDoctor._id,
                            clinicId: clinic._id,
                            newStartTime: startTime,
                            newEndTime: endTime,
                        }
                    });
                else
                    await AddTiming({
                        data: {
                            doctorId: selectedDoctor._id,
                            clinicId: clinic._id,
                            newDay: dayOfWeek,
                            startTime: startTime,
                            endTime: endTime,
                        }
                    });
                if (onSubmit != null)
                    onSubmit();
            } catch (error) {
                console.error(error);
            }
            if (timing?._id)
                setSubmitText("Save")
            else
                setSubmitText("Create")
            setSubmitDisabled(false);
        }
    }

    return (
        <form onSubmit={handleSubmit} className='d-flex flex-column gap-2'>
            <div className='d-flex flex-wrap gap-2'>
                {doctor == null && (
                    <div className='col-12 col-sm-4 form-floating'>
                        <input className='form-control' type='text' placeholder='Search Doctor by Name or Registration' value={docquery} onChange={(e) => {
                            setDocquery(e.target.value);
                            document.getElementById('select-doctor').value = '';
                        }} />
                        <label className='form-label'>Search Doctor</label>
                    </div>
                )}
                <div className='col form-floating'>
                    <select className='form-select' id="select-doctor" defaultValue={selectedDoctor?._id ?? ''} required={true} disabled={doctor} onChange={(e) => setSelectedDoctor(doctors.find(doctor => doctor._id === e.target.value))}>
                        <option value='' disabled={true}>Select Doctor (Please use searchbar to get options)</option>
                        {doctors.map((doctor, index) => (
                            <option key={index} value={doctor._id}>{doctor.name} ({doctor.regNumber})</option>
                        ))}
                    </select>
                    <label className='form-label'>Doctor</label>
                </div>
            </div>
            <div className='input-group'>
                <div className='form-floating'>
                    <select className='form-select' defaultValue={dayOfWeek} required={true} disabled={timing?._id} onChange={(e) => setDayOfWeek(e.target.value)}>
                        <option value='' disabled={true}>Select Day</option>
                        <option value='Sun'>Sunday</option>
                        <option value='Mon'>Monday</option>
                        <option value='Tue'>Tuesday</option>
                        <option value='Wed'>Wednesday</option>
                        <option value='Thu'>Thursday</option>
                        <option value='Fri'>Friday</option>
                        <option value='Sat'>Saturday</option>
                    </select>
                    <label className='form-label'>Day</label>
                </div>
                <div className='form-floating'>
                    <input className='form-control' type='time' value={startTime} required={true} onChange={(e) => setStartTime(e.target.value)} />
                    <label className='form-label'>Start Time</label>
                </div>
                <div className='form-floating'>
                    <input className='form-control' type='time' value={endTime} required={true} onChange={(e) => setEndTime(e.target.value)} />
                    <label className='form-label'>End Time</label>
                </div>
            </div>
            <button type='submit' className='btn btn-lg btn-primary' disabled={submitDisabled}>{submitText}</button>
        </form>
    );
}

export default TimingForm;