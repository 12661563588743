const Result = ({ result }) => {
    const parsedTimings = () => {
        const dayTimingMap = {};

        result.timings.split(',').forEach(timing => {
            // eslint-disable-next-line
            const [day, timeRange, id] = timing.split(" | ");
            if (!dayTimingMap[day.trim()]) {
                dayTimingMap[day.trim()] = [];
            }
            dayTimingMap[day.trim()].push(timeRange);
        });

        return dayTimingMap;
    };

    return (
        <div className="d-flex flex-wrap justify-content-evenly align-items-center">
            {result.photo ? (
                <div>
                    <img src="/assets/doctor-icon.png" style={{ minWidth: 150 + "px", maxWidth: 200 + "px" }} alt="avatar" />
                </div>
            ) : (
                <></>
            )}
            <div className="my-3">
                <div className="text-center">
                    <h3 className="card-title">{result.doctorName}</h3>
                    <h6 className="card-subtitle mb-2 text-body-secondary">{result.speciality} | {result.qualification}</h6>
                </div>
                <div className="d-flex gap-2 justify-content-center my-4">
                    <i className="fs-2 lh-1 bi bi-currency-rupee"></i>
                    <h2 className="lh-1">
                        {result.fee}
                    </h2>
                </div>
                <div className="my-2 d-flex flex-column gap-2">
                    <div className="d-flex gap-2 align-items-start">
                        <i className="fs-5 lh-1 bi bi-geo-alt"></i>
                        <h5 className="lh-1">{result.address}</h5>
                    </div>
                    <div className="d-flex gap-2 align-items-start">
                        <i className="fs-5 lh-1 bi bi-map"></i>
                        <h5 className="lh-1">
                            <a className="text-decoration-none" target="_blank" rel="noreferrer" href={result.map_link}>View in Map</a>
                        </h5>
                    </div>
                    <div className="d-flex gap-2 align-items-start">
                        <i className="fs-5 lh-1 bi bi-hourglass-bottom"></i>
                        <h5 className="lh-1">
                            {result.experience} Years Experience
                        </h5>
                    </div>
                    <div className="d-flex gap-2 align-items-start">
                        <i className="fs-5 lh-1 bi bi-hospital"></i>
                        <h5 className="lh-1">
                            {result.clinicName}
                        </h5>
                    </div>
                    <div className="d-flex gap-2 align-items-start">
                        <i className="fs-5 lh-1 bi bi-clock"></i>
                        <div>
                            {Object.entries(parsedTimings()).map(([day, slots], index) => (
                                <div key={"day-" + index} className="d-flex gap-2">
                                    <h5 className="lh-1">{day}</h5>
                                    <span className="fs-5 lh-1 text-body-secondary">
                                        {slots.join(", ")}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Result;