import Modal from "./Modal";
import { DeleteDoctor } from "../api/api";


const DoctorCard = ({ doctor, onChange = null, form = null }) => {
    return (
        <div className='card' style={{ minWidth: 500 + "px" }}>
            <div className="card-body d-flex flex-wrap align-items-stretch">
                {doctor.image ? (
                    <img src={doctor.image} style={{ minWidth: 300 + "px" }} alt="avatar" />
                ) : (
                    <></>
                )}
                <div>
                    <h5 className="card-title">{doctor.gender} {doctor.name}</h5>
                    <h6 className="card-subtitle mb-2 text-body-secondary">{doctor.speciality} | {doctor.qualification} | {doctor.experience} Years Experience</h6>
                    <h4>Rs. {doctor.fee}</h4>
                    <div className="mb-3">
                        <div>Registration: {doctor.regNumber}</div>
                        <div>Phone: {doctor.phone}</div>
                        <div>Email: {doctor.email}</div>
                    </div>
                    {form != null ? (
                        <div className="d-flex gap-2">
                            <Modal modalId={"edit-doctor-" + doctor._id} title={"Edit " + doctor.name} body={form}
                                buttonClassName="btn btn-outline-primary"
                                buttonContent={
                                    <i className='bi bi-pen'></i>
                                } />
                            <Modal modalId={"delete-doctor-" + doctor._id} title={"Delete " + doctor.name + "?"}
                                modaldialogClassNames="modal-dialog-centered modal-dialog-xs"
                                body={
                                    <div>
                                        Registration number: {doctor.regNumber}
                                    </div>
                                }
                                footer={
                                    <button className="btn btn-danger" onClick={async () => {
                                        await DeleteDoctor({ data: { doctorId: doctor._id } });
                                        document.getElementById(`delete-doctor-${doctor._id}-close`).click();
                                        onChange();
                                    }}>Delete</button>
                                }
                                buttonClassName="btn btn-outline-danger"
                                buttonContent={
                                    <i className='bi bi-trash'></i>
                                } />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DoctorCard;