import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import { DeleteClinic, VerifyClinicOtp, GetClinicOtp } from "../api/api";
import TimingForm from "./TimingForm";


const ClinicCard = ({ clinic, onChange = null, form = null }) => {
    const navigate = useNavigate();

    return (
        <div className='card' style={{ minWidth: 500 + "px" }}>
            <div className="card-body d-flex flex-wrap align-items-stretch">
                {clinic.photo ? (
                    <img src={clinic.photo} style={{ minWidth: 300 + "px" }} alt="avatar" />
                ) : (
                    <></>
                )}
                <div>
                    <h5 className="card-title">{clinic.name}</h5>
                    <h6 className="card-subtitle mb-2 text-body-secondary">{clinic.address.locality}, {clinic.address.city}, PIN:{clinic.address.pincode}</h6>
                    <p>Phone: {clinic.phone}</p>
                    <p>Map Link: <a href={clinic.map_link} target="_blank" rel="noreferrer">{clinic.map_link}</a></p>
                    {form != null ? (
                        <div className="d-flex gap-2">
                            {!clinic.otpVerified && (
                                <Modal modalId={"verify-otp-clinic-" + clinic._id} title={"Verify OTP for " + clinic.name}
                                    modaldialogClassNames="modal-dialog-centered modal-dialog-xs"
                                    body={
                                        <div className="input-group">
                                            <button className="btn btn-outline-accent" onClick={async () => {
                                                await GetClinicOtp({ data: { clinicId: clinic._id } });
                                            }}>Get OTP</button>
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    id="clinic-otp-input"
                                                    className="form-control"
                                                    placeholder="OTP"
                                                />
                                                <label className="form-label">OTP</label>
                                            </div>
                                            <button className="btn btn-outline-accent" onClick={async () => {
                                                await VerifyClinicOtp({ data: { clinicId: clinic._id, otp: document.getElementById("clinic-otp-input").value } });
                                                document.getElementById(`verify-otp-clinic-${clinic._id}-close`).click();
                                                onChange();
                                            }}>Verify</button>
                                        </div>
                                    }
                                    buttonContent="Verify OTP" />
                            )}
                            <Modal modalId={"add-timing-clinic-" + clinic._id} title={"Add timing for " + clinic.name}
                                modaldialogClassNames="modal-dialog-centered modal-dialog-xs"
                                body={
                                    <TimingForm clinic={clinic} onSubmit={() => {
                                        document.getElementById(`add-timing-clinic-${clinic._id}-close`).click();
                                        onChange();
                                    }} />
                                }
                                buttonClassName="btn btn-outline-primary"
                                buttonContent={
                                    <span className="d-flex align-items-center gap-2">
                                        <i className="bi bi-plus-circle lh-1"></i>
                                        <span className="lh-1">Add Timing</span>
                                    </span>
                                } />
                            <Modal modalId={"edit-clinic-" + clinic._id} title={"Edit " + clinic.name} body={form}
                                buttonClassName="btn btn-outline-primary"
                                buttonContent={
                                    <i className='bi bi-pen'></i>
                                } />
                            <Modal modalId={"delete-clinic-" + clinic._id} title={"Delete " + clinic.name}
                                modaldialogClassNames="modal-dialog-centered modal-dialog-xs"
                                body={
                                    <div>
                                        Address: {clinic.address.locality}, {clinic.address.city}, PIN:{clinic.address.pincode}
                                    </div>
                                }
                                footer={
                                    <button className="btn btn-danger" onClick={async () => {
                                        await DeleteClinic({ data: { clinicId: clinic._id } });
                                        document.getElementById(`delete-clinic-${clinic._id}-close`).click();
                                        navigate("/admin/clinics/")
                                    }}>Delete</button>
                                }
                                buttonClassName="btn btn-outline-danger"
                                buttonContent={
                                    <i className='bi bi-trash'></i>
                                } />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ClinicCard;