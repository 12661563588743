import React, { useEffect, useState } from 'react';
import Result from '../components/Result';
import BookResult from '../components/BookResult';
import SearchForm from '../components/SearchForm';
import Modal from '../components/Modal';

const Search = ({ setCityIndex, user = null, cities = [], cityIndex = 0, specialities = [] }) => {
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [shown, setShown] = useState(0);
    const [total, setTotal] = useState(0);
    const [text, setText] = useState('No Results. Update your Serach filters and try again.');

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'DocInJob | Search';
    }, []);

    return (
        <div className="container col-xxl-10 px-3 py-5">
            <SearchForm setResults={setResults} setPage={setPage} setPages={setPages} setShown={setShown} setTotal={setTotal} setCityIndex={setCityIndex} setText={setText} results={results} page={page} cities={cities} cityIndex={cityIndex} specialities={specialities} />
            <div className='text-body-secondary mt-3'>Showing {shown} of {total} Results</div>
            <div className='my-3 d-flex gap-3 flex-wrap'>
                {(results ?? []).length ?
                    results.map((result, index) => (
                        <div key={`result-${index}`} className="card" style={{ width: 24 + "rem" }}>
                            <div className="card-body w-100 d-flex flex-column">
                                <Result result={result} />
                                <Modal key={`result-${index}`} modalId={`result-${index}`} title="Book Appointment" buttonClassName='mt-auto btn btn-primary w-100' body={<BookResult result={result} user={user} />} buttonContent="Quick View" />
                            </div>
                        </div>
                    )) : (
                        <div>{text}</div>
                    )
                }
                {page < pages ? (
                    <button className="btn btn-primary w-100" onClick={() => setPage(page + 1)}>Load More</button>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}

export default Search;