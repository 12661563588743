const Modal = ({ modalId, title, body, footer = null, modaldialogClassNames = "modal-xl modal-fullscreen-sm-down", buttonClassName = "btn btn-primary", buttonContent = "Show" }) => {
    return (
        <>
            <div className="modal fade" id={modalId} tabindex="-1" aria-hidden="true">
                <div className={`modal-dialog ${modaldialogClassNames}`}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5">{title}</h1>
                            <button id={modalId + "-close"} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {body}
                        </div>
                        {footer != null ? (
                            <div className="modal-footer">
                                {footer}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            <button type="button" className={buttonClassName} data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                {buttonContent}
            </button>
        </>
    );
}

export default Modal;