import React, { useEffect } from 'react';


const Surgery = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'DocInJob | Surgery';
    }, []);

    return (
        <div className="d-flex">
            <div className='px-5 my-5 py-5'>
                <h1>Coming Soon</h1>
                <p>We are working hard to bring you this feature. Stay tuned!</p>
            </div>
        </div>
    );
};

export default Surgery;