import React, { useState, useEffect } from 'react';
import { CreateClinic, UpdateClinic } from '../api/api';


const ClinicForm = ({ clinic = null, onSubmit = null }) => {
    const [submitText, setSubmitText] = useState('Save')
    const [submitDisabled, setSubmitDisabled] = useState(false)

    const [name, setName] = useState(clinic?.name ?? "");
    const [locality, setLocality] = useState(clinic?.address.locality ?? "");
    const [city, setCity] = useState(clinic?.address.city ?? "");
    const [pin, setPin] = useState(clinic?.address.pincode ?? "");
    const [mapLink, setMapLink] = useState(clinic?.map_link ?? "");
    const [photo, setPhoto] = useState(clinic?.photo ?? "");
    const [phone, setPhone] = useState(clinic?.phone ?? "");


    useEffect(() => {
        if (clinic?._id)
            setSubmitText("Save")
        else
            setSubmitText("Create")
    }, [clinic]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name.trim() && locality.trim() && city.trim() && pin.trim() && mapLink.trim() && photo.trim() && phone.trim()) {
            setSubmitText('Saving...');
            setSubmitDisabled(true);
            try {
                if (clinic?._id)
                    await UpdateClinic({
                        data: {
                            clinicId: clinic?._id,
                            name: name,
                            address: {
                                locality: locality,
                                city: city,
                                pincode: pin,
                            },
                            map_link: mapLink,
                            photo: photo,
                            phone: phone,
                        }
                    });
                else
                    await CreateClinic({
                        data: {
                            name: name,
                            address: {
                                locality: locality,
                                city: city,
                                pincode: pin,
                            },
                            map_link: mapLink,
                            photo: photo,
                            phone: phone,
                        }
                    });
                if (onSubmit != null)
                    onSubmit();
            } catch (error) {
                console.error('Error Saving:', error.response ? error.response.data : error.message);
            }
            setSubmitText('Save');
            setSubmitDisabled(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{ minWidth: 300 + 'px' }}>
            <div className="form-floating mb-2">
                <input
                    type="text"
                    id="clinic-name-input"
                    className="form-control"
                    value={name}
                    placeholder="Name"
                    required={true}
                    onChange={(e) => setName(e.target.value)}
                />
                <label className="form-label">Name</label>
            </div>
            <div className="d-flex flex-wrap gap-2 mb-2">
                <div className="col-12 col-lg form-floating">
                    <input
                        type="text"
                        id="clinic-locality-input"
                        className="form-control"
                        value={locality}
                        placeholder="Locality"
                        required={true}
                        onChange={(e) => setLocality(e.target.value)}
                    />
                    <label className="form-label">Locality</label>
                </div>
                <div className="col-12 col-sm d-flex flex-wrap gap-2">
                    <div className="col form-floating">
                        <input
                            type="text"
                            id="clinic-city-input"
                            className="form-control"
                            value={city}
                            placeholder="City"
                            required={true}
                            onChange={(e) => setCity(e.target.value)}
                        />
                        <label className="form-label">City</label>
                    </div>
                    <div className="col form-floating">
                        <input
                            type="number"
                            id="clinic-pincode-input"
                            className="form-control"
                            value={pin}
                            placeholder="PIN Code"
                            required={true}
                            onChange={(e) => setPin(e.target.value)}
                        />
                        <label className="form-label">PIN Code</label>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-wrap gap-2 mb-2">
                <div className="col form-floating">
                    <input
                        type="text"
                        id="clinic-map-link-input"
                        className="form-control"
                        value={mapLink}
                        placeholder="Map Link"
                        required={true}
                        onChange={(e) => setMapLink(e.target.value)}
                    />
                    <label className="form-label">Map Link</label>
                </div>
                <div className="col form-floating">
                    <input
                        type="text"
                        id="clinic-photo-input"
                        className="form-control"
                        value={photo}
                        placeholder="Photo"
                        required={true}
                        onChange={(e) => setPhoto(e.target.value)}
                    />
                    <label className="form-label">Photo</label>
                </div>
            </div>
            <div className="form-floating mb-2">
                <input
                    type="tel"
                    id="clinic-phone-input"
                    className="form-control"
                    value={phone}
                    placeholder="Phone"
                    required={true}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <label className="form-label">Phone</label>
            </div>
            <button type="submit" className="btn btn-lg btn-primary w-100" disabled={submitDisabled}>{submitText}</button>
        </form>
    );
}

export default ClinicForm;