import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";

const AdminLayout = ({ adminUser = null, getAdminUser = null }) => {
    const nav_items = [
        ["Clinics", "/admin/clinics/"],
        ["Doctors", "/admin/doctors/"],
    ];

    return (
        <>
            <header className="App-header">
                <Navbar items={nav_items} adminUser={adminUser} logout={true} login={false} getUser={getAdminUser} />
            </header>

            <Outlet />
        </>
    )
};

export default AdminLayout;