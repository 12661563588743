const TermsAndConditions = () => {
    return (
        <div class="box-body">
            <h3 style={{ textDecoration: 'underline', textAlign: 'center' }}>Terms &amp; Conditions</h3>
            <p>The following sets forth the general terms and conditions (the “Terms”) between <b>DocInJob Pvt Ltd</b> and users of its Website at www.docinjob.com (the “Site”) and of the services, features, content, applications or widgets offered by DocInJob (collectively with the site, the "Service"). By using the DocInJob Website and the DocInJob application (the “DocInJob-App”) and/or the Services, features, content, applications or widgets offered by DocInJob, you agree to be bound by these Terms. PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE SERVICES</p>
            <p>The terms and conditions govern the use of the website and DocInJob Application <b>www.docinjob.com</b> or for our application <b>QUICKOBOOK</b> including all sections and services available on this website and DocInJob Application. Accessing, Browsing or using the site and DocInJob Application or our <b>QUICKOBOOK</b> application indicate your consent to our agreement for terms of use. So, Please read the terms of use carefully before any further proceedings. What we actually render through this site and DocInJob Application is referred to in these Terms of Use collectively known as the “Services."</p>
            <p>We may make future changes or modifications to such terms and conditions at any time without notice, and any subsequent viewing or use of our website and DocInJob Application will constitute the user’s agreement to the changes and modifications. There may be additional terms and conditions provided throughout the website and DocInJob Application governing your use of particular functions, features, information and applications available through the website and DocInJob Application.</p>
            <p>As part of the registration process, you need to provide an e-mail address and create a password. These are your credentials for accessing the Services that are only available to members. Vulgarity of words, attempts to impersonate another person violate the rights of others.</p>
            <p>Certain of the Services on the Site and DocInJob Application may have additional terms (such as policies, guidelines, and rules) that will further govern your use of that particular Service and supplement these Terms of Use. If you choose to register for or access any such Services, you will be presented with any relevant additional terms and conditions at that time. By using those Services, you agree to comply with such additional guidelines and rules.</p>
            <p>You may have the opportunity to submit feedback regarding your experiences with doctors who are featured on the Site and DocInJob Application. It is important that you act responsibly when providing this feedback. Please give clear, honest information about the doctor and your experiences, but do not use inappropriate language or make gratuitous personal criticisms or comments.</p>
            <ul class="content-list">
                <li><strong>The Agreement applies for</strong>
                    <ul>
                        <li>A doctor or an individual professional or an organization or similar institution wishing to be listed, or already listed, on the <strong>Website and DocInJob Application</strong></li>
                        <li>Otherwise a user of the Website and DocInJob Application (“you” or “User”).</li>
                    </ul>
                </li>
                <li><strong>This Agreement applies to those services made available by DocInJob.com on the Website and DocInJob Application, which are offered free of charge to the Users (“Services”), including the following:</strong>
                    <ul>
                        <li>Search for Doctors by name, specialty is made available to DocInJob or any other criteria that may be developed and made</li>
                        <li>The Services may change from time to time, at the sole discretion of docinjob and the agreement will apply to your visit to and your use of the Website and DocInJob Application to avail the Service with all information provided by you on the Website and DocInJob Application at any given point in time.</li>
                    </ul>
                </li>

                <li>DocInJob.com deemed with the right to modify or terminate any Doctor or Partner or a Franchisee from being the part of DocInJob for any reason and at any point of time and such modifications shall be informed to you in writing in our website and DocInJob Application or communicating the same message to your e-mail address. You should read the agreement at regular intervals. Your use of the Website and DocInJob Application following any such modification constitutes your agreement to follow and be bound by the agreement so modified. Any additional terms and conditions, disclaimers, privacy policies and other policies applicable in general and/ or to specific areas of this Website and DocInJob Application or to particular Service are also considered as part of the agreement.</li>
                <li><strong>CONDITIONS OF USE</strong></li>
                <li>You must be 18 years of age or older to register, use the Services, or visit or use the Website and DocInJob Application in any manner. By registering, visiting and using the Website and DocInJob Application or accepting this Agreement, you represent and warrant to DocInJob that you are 18 years of age or older, and that you have the right, authority and capacity to use the Website and DocInJob Application and the Services available through the Website and DocInJob Application, and agree to and abide by this Agreement.</li>
                <li><b>TERMS OF USE APPLICABLE TO ALL USERS</b>
                    <ul>
                        <li>DocInJob may by its Services, collect information relating to the devices through which you access the website and DocInJob Application, and anonymous data of your usage. The collected information will be used only for improving the quality of our services and to build new services.</li>
                        <li>The Website and DocInJob Application allows DocInJob to have access to registered Users’ personal email or phone number, for communication purpose so as to provide you a better way of booking appointments and for obtaining feedback in relation to the doctors and their practice.</li>
                    </ul>
                </li>
                <li>The User is expected to read and understand the Privacy Policy, so as to ensure that he or she has the knowledge of
                    <ul>
                        <li>The fact that certain information is being collected;</li>
                        <li>The purpose for which the information is being collected;</li>
                    </ul>
                </li>
                <li>DocInJob shall not be responsible in any manner for the authenticity of the personal information or sensitive personal data or information supplied by the user to DocInJob or to any other person acting on behalf of DocInJob
                    <ul>
                        <li>When any User provides any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or DocInJob has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, DocInJob has the right to discontinue the service</li>
                        <li>DocInJob may use such information collected from the Users from time to time for the purposes of debugging customer support related issues.</li>
                    </ul>
                </li>
                <li><strong>LISTING CONTENT</strong></li>
                <li>
                    <ul>
                        <li>DocInJob collects, directly or indirectly, and displays on the Website and DocInJob Application, relevant information regarding the profile and practice of the doctors listed on the Website and DocInJob Application, such as their specialisation, qualification, fees, location, visiting hours, and similar details.</li>
                    </ul>
                </li>
                <li>DocInJob takes reasonable efforts to ensure that such information is updated at frequent intervals. Although DocInJob screens and gets the information and photos submitted by the doctors, it cannot be held liable for any in accuracies or incompleteness represented from it, despite such reasonable efforts.</li>
                <li>
                    <ul>
                        <li>The Services provided by DocInJob or any of its licensors or service providers are provided on an "as is" and “as available’ basis, and without any warranties or conditions. DocInJob does not provide or make any representation, warranty or guarantee, express or implied about the website and DocInJob Application or the services. DocInJob does not guarantee the accuracy or completeness of any content or information provided by Users on the Website and DocInJob Application.</li>
                        <li>The Website and DocInJob Application may be linked to the website and DocInJob Application of third parties, affiliates and business partners. DocInJob has no control over, and not liable or responsible for content, accuracy, validity, reliability, quality of such website and DocInJob Applications or made available by/through our Website and DocInJob Application. Inclusion of any link on the Website and DocInJob Application does not imply that DocInJob endorses the linked site and DocInJob Application. User may use the links and these services at User’s own risk.</li>
                        <li>DocInJob assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect User’s equipment on account of User’s access to, use of, or browsing the website and DocInJob Application or the downloading of any material, data, text, images, video content, or audio content from the Website and DocInJob Application. If a User is dissatisfied with the Website and DocInJob Application, User’s sole remedy is to discontinue using the Website and DocInJob Application.</li>
                    </ul>
                </li>
                <li>If DocInJob determines that you have provided fraudulent, inaccurate, or incomplete information, including through feedback, DocInJob reserves the right to immediately suspend your access to the Website and DocInJob Application or any of your accounts with DocInJob and makes such declaration on the website and DocInJob Application alongside your name/your clinic’s name as determined by DocInJob for the protection of its business and in the interests of Users. You shall be liable to indemnify DocInJob for any losses incurred as a result of your misrepresentations or fraudulent feedback that has adversely affected DocInJob or its Users.</li>
                <li><strong>APPOINTMENT BOOKING AND INTERACTION WITH DOCOTRS</strong></li>
                <li>While DocInJob will try to ensure a confirmed appointment for the User who requested an appointment on Website and DocInJob Application, DocInJob does not guarantee that a patient will get a confirmed appointment. Further, DocInJob has no liability if such appointment is confirmed but later cancelled by Doctors or the Doctors are not available as per the given appointment time.</li>
                <li><b>Without prejudice to the generality of the above, DocInJob will not be liable for</b>
                    <ul>
                        <li>any wrong medication or treatment quality being given by the Doctor(s), or any medical negligence on part of the Doctor (s);</li>
                        <li>any type of inconvenience suffered by the User due to a failure on the part of the Doctor to provide agreed services or to make himself/herself available at the appointed time, no show by the Doctor, inappropriate treatment, or similar difficulties;</li>
                        <li>any misconduct or inappropriate behavior by the Doctor or the Doctor’s staff;</li>
                        <li>cancellation or rescheduling of booked appointment or any variance in the fees charged;</li>
                        <li>any medical eventualities that might occur subsequent to using the services of a Doctor, whom the User has selected on the basis of the information available on the Website and DocInJob Application or with whom the User has booked an appointment through the Website and DocInJob Application.</li>
                    </ul>
                </li>
                <li>Further, DocInJob shall not be liable, under any event, for any comments or feedback given by any of the Users in relation to the services provided by another User. All such feedback should be made in accordance with applicable law. The option of Users to give feedback remains at DocInJob sole discretion and may be modified or withdrawn at its sole discretion.</li>
                <li>DocInJob may moderate such feedback at any time. DocInJob shall not be obliged to act in any manner to give effect to the content of Users’ feedback, such as suggestions for delisting of a particular Practitioner from the Website and DocInJob Application.</li>
                <li><b>NO DOCTOR – PATIENT RELATIONSHIP; NOT FOR EMERGENCY USE</b></li>
                <li>Please note that some of the content, text, data, graphics, images, information, suggestions, guidance, and other material (collectively, “Information”) that may be available on the Website and DocInJob Application (including information provided in direct response to your questions or postings) may be provided by individuals in the medical profession. The provision of such Information does not create a licensed medical professional/patient relationship, between DocInJob and you and does not constitute an opinion, medical advice, or diagnosis or treatment of any particular condition, but this is only provided to assist you with locating appropriate medical care from a qualified doctor.</li>
                <li>It is hereby expressly clarified that, the Information that you obtain or receive from DocInJob, and its employees, contractors, partners, sponsors, advertisers, licensors or otherwise on the Website and DocInJob Application is for informational purposes only. We make no guarantees, representations or warranties, whether expressed or implied, with respect to professional qualifications, quality of work, expertise or other information provided on the Website and DocInJob Application. In no event shall we be liable to you or anyone else for any decision made or action taken by you in reliance on such information.</li>
                <li>The Services are not intended to be a substitute for getting in touch with emergency healthcare. If you are a user facing a medical emergency, please contact an ambulance service or hospital directly.</li>
                <li><strong>CONTENT OWNERSHIP AND COPYRIGHT- CONDITIONS OF ACCESS</strong></li>
                <li>The contents listed on the Website and DocInJob Application are (i) User generated content, or (ii) belong to DocInJob. The information that is collected by DocInJob directly or indirectly from the Users and the Doctors shall belong to DocInJob.com copying of the copyrighted content published by DocInJob on the Website and DocInJob Application for any commercial purpose or for the purpose of earning profit will be a violation of copyright and DocInJob reserves its rights under applicable law accordingly</li>
                <li>DocInJob authorizes the User to view and access the content available on or from the Website and DocInJob Application solely for ordering, receiving, delivering and communicating only as per this Agreement. The contents of the Website and DocInJob Application, information, text, graphics, images, logos, button icons, software code, design, and the collection, arrangement and assembly of content on the Website and DocInJob Application (collectively, "DocInJob Content" ), are the property of DocInJob and are protected under copyright and other laws. User shall not modify the DocInJob Content or reproduce, display, publicly perform, distribute, or otherwise use the DocInJob Content in any way for any public or commercial purpose or for personal gain. User shall not access the Services for purposes of monitoring their availability, performance or functionality, or for any other benchmarking or competitive purposes.</li>
                <li><strong>REVIEWS AND FEEDBACK</strong></li>
                <li>By using this Website and DocInJob Application, you agree that any information shared by you with DocInJob or with any Practitioner will be subject to our Privacy Policy.</li>
                <li>DocInJob at its discretion may or may not display your reviews and feedback. You agree that DocInJob may contact you through telephone, email, SMS, or any other electronic means of communication for the purpose of:</li>
                <li><strong>TERMS OF USE – PRACTITIONERS – (Doctors)</strong>
                    <ul>
                        <li><b>LISTING POLICY</b><br /><br />DocInJob, directly and indirectly, collects information regarding the Doctor’s profiles, contact details, and practice. DocInJob reserves the right to take down any Doctor’s profile as well as the right to display the profile of the Doctor’s, with or without notice to the concerned doctor. This information is collected for the purpose of facilitating interaction with the User. If any information displayed on the Website and DocInJob Application in connection with you and your profile is found to be incorrect, you are required to inform DocInJob immediately to enable DocInJob to make the necessary amendments.<br /><br />You as a Doctor hereby represent and warrant that you will use the Services in accordance with and shall render the service to the User of the website and DocInJob Application at any cost. Any contravention of applicable law as a result of your use of these Services is your sole responsibility, and DocInJob accepts no liability for the same.</li>
                    </ul>
                </li>
                <li>
                    <strong>PROFILE OWNERSHIP AND EDITING RIGHT</strong>
                </li>
                <li>DocInJob ensures easy access to the Doctor by providing a tool to update their profile with true information by login into their account and be interconnected with the patients. Doctors may send delayed message for his/her appointment or any other such types of messages which shall have implications for giving better service to the customer but if incase it is found with any activity that may hamper or cause damage to the image of docinjob then and there the consequences may go severe with time .</li>
                <li>DocInJob doesn’t take any responsibility for cancellation of tickets booked and no refund of paid amount will be done to any of the customer. DocInJob reserves the right of ownership of all the Practitioner’s profile and photographs and to moderate the changes or updates requested by Practitioners. However, DocInJob takes the independent decision whether to publish or reject the requests submitted for the respective changes or updates. You hereby represent and warrant that you are fully entitled under law to upload all content uploaded by you as part of your profile or otherwise while using DocInJob’s services, and that no such content breaches any third party rights, including intellectual property rights.</li>
                <li><strong>REVIEWS AND FEEDBACK DISPLAY RIGHTS OF QUICKOBOOK.COM</strong></li>
                <li>DocInJob reserves the right to collect feedback and Critical Content for all the Practitioners, Clinics and Healthcare Providers listed on the Website and DocInJob Application.</li>
                <li>DocInJob reserves the right but shall have no obligation to pre-screen, review, flag, filter, modify, refuse or remove any or all Critical Content from any Service</li>
                <li>DocInJob reserves the right to moderate, publish, re-publish and use all user generated contributions and comments (including but not limited to reviews) posted on the Website and DocInJob Application as it deems appropriate (whether in whole or in part) for its product (s), whether owned or affiliated.</li>
                <li>DocInJob shall not be liable to pay any considerations to any User for republishing any content across any of its platforms.</li>
                <li>You understand that by using the Services you may be exposed to Content that you may find offensive, indecent or objectionable and that, in this respect, you may use the Service at your own risk</li>
                <li>DocInJob will take down information under standards consistent with applicable law, and shall in no circumstances be liable or responsible for Critical Content, which has been created by the Users. The principles set out in relation to third party content in the terms of Service for the Website and DocInJob Application shall be applicable mutatis mutandis in relation to Critical Content posted on the Website and DocInJob Application.</li>
                <li><strong>QUICKOBOOK REACH RIGHTS</strong></li>
                <li>DocInJob reserves the rights to display sponsored ads on the Website and DocInJob Application. These ads would be marked as “Sponsored Listings”. Without prejudice to the status of other content, DocInJob will not be liable for the accuracy of information or the claims made in the Sponsored Listings. DocInJob does not encourage the Users to visit the Sponsored Listings page or to avail any services from them. DocInJob will not be liable for the services of the providers of the Sponsored Listings.</li>
                <li>You represent and warrant that you will use these Services in accordance with applicable law. Any contravention of applicable law as a result of your use of these Services is your sole responsibility, and DocInJob accepts no liability for the same.</li>
                <li><b>RIGHTS AND OBLIGATIONS RELATING TO CONTENT</b></li>
                <li>The User is also prohibited from</li>
                <li>
                    <ul>
                        <li>violating or attempting to violate the integrity or security of the Website and DocInJob Application or any DocInJob Content;</li>
                        <li>transmitting any information (including job posts, messages and hyperlinks) on or through the Website and DocInJob Application that is disruptive or competitive to the provision of Services by DocInJob;</li>
                        <li>intentionally submitting on the Website and DocInJob Application any incomplete, false or inaccurate information;</li>
                        <li>making any unsolicited communications to other Users;</li>
                        <li>using any engine, software, tool, agent or other device or mechanism (such as spiders, robots, avatars or intelligent agents) to navigate or search the Website and DocInJob Application;</li>
                        <li>attempting to decipher, decompile, disassemble or reverse engineer any part of the Website and DocInJob Application;</li>
                        <li>copying or duplicating in any manner any of the DocInJob Content or other information available from the Website and DocInJob Application;</li>
                        <li>Framing or hot linking or deep linking any DocInJob Content. DocInJob, upon obtaining knowledge by itself or been brought to actual knowledge by an affected person in writing as mentioned above, shall be entitled to disable such information.</li>
                    </ul>
                </li>
                <li>DocInJob may disclose or transfer User-generated information to its affiliates, in such manner as permitted by applicable law, and you hereby consent to such transfer.</li>
                <li><strong>TERMINATION</strong></li>
                <li>DocInJob reserves the right to suspend or terminate a User’s access to the Website and DocInJob Application and the Services with or without notice and to exercise any other remedy available under law, in cases where,
                    <ul>
                        <li>Such User breaches any terms and conditions of the Agreement;</li>
                        <li>A third party reports violation of any of its right as a result of your use of the Services</li>
                        <li>DocInJob is unable to verify or authenticate any information provide to DocInJob by a User;</li>
                    </ul>
                </li>
                <li>DocInJob has reasonable grounds for suspecting any illegal, fraudulent or abusive activity on part of such User.</li>
                <li><b>CONTACT INFORMATION</b></li>
                <li>Any type of grievances are welcomed. If User has any query can take up the concern to DocInJob customer support can be reached at the following email address Info@docinjob.com or you can contact our office at</li>
                <li><b>DocInJob Pvt Ltd</b>
                    <ul>
                        <li><strong>Registered Office: Silchar</strong></li>
                        <li><strong>Email: info@docinjob.com</strong></li>
                        <li><strong>Helpline: 8822882288</strong></li>
                    </ul>
                </li>
                <li>YOU HAVE READ THESE TERMS OF USE AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE</li>
            </ul>
        </div>
    );
};

export default TermsAndConditions;