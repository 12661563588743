const ContactInfo = () => {
    return (
        <div className="list-group-flush">
            <span className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                <i className="bi bi-geo-alt"></i>
                <div className="d-flex gap-2 w-100 justify-content-between">
                    <div>
                        <h6 className="mb-0">Silchar, Assam, 781011, India</h6>
                        <p className="mb-0 opacity-75">Head office</p>
                    </div>
                </div>
            </span>
            <a href="tel:+918822882288" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                <i className="bi bi-telephone"></i>
                <div className="d-flex gap-2 w-100 justify-content-between">
                    <div>
                        <h6 className="mb-0">+91 8822882288</h6>
                        <p className="mb-0 opacity-75">Contact</p>
                    </div>
                </div>
            </a>
            <a href="mailto:info@docinjob.com" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                <i className="bi bi-envelope"></i>
                <div className="d-flex gap-2 w-100 justify-content-between">
                    <div>
                        <h6 className="mb-0">info@docinjob.com</h6>
                        <p className="mb-0 opacity-75">Email</p>
                    </div>
                </div>
            </a>
        </div>
    );
}

export default ContactInfo;