import { Link } from "react-router-dom";
import Logo from "./Logo";
import ContactButton from "./ContactButton";
import ContactInfo from "./ContactInfo";

const Footer = ({ nav_items = [] }) => {
    return (
        <footer className="bg-primary-subtle">
            <div className="p-5">
                <div className="d-flex justify-content-evenly flex-wrap gap-5 py-5">
                    <div className="col-12 col-md-8 col-lg-4 col-xxl-3 flex-md-shrink d-flex flex-column align-items-center align-items-md-start gap-4">
                        <Logo width={300} widthUnit="px" />
                        <ContactButton />
                        <p>
                            DocInJob is a registered start up company empaneled with 10000+ Doctors, 500+ Hospitals, lives touched of more than 2 Million patients.
                        </p>
                    </div>
                    <ul className="nav flex-column text-center text-sm-start">
                        {nav_items.map((item, index) =>
                            Array.isArray(item[1]) ? (
                                <li key={index} className="nav-item p-2 dropdown">
                                    <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover dropdown-toggle" href={"#" + item[0] + "menu"} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {item[0]}
                                    </a>
                                    <ul id={item[0] + "menu"} className="dropdown-menu">
                                        {item[1].map((subItem, subIndex) => (
                                            <li key={subIndex}>
                                                {subItem.href.includes("#") ? (
                                                    <a className="dropdown-item" href={subItem.href}>{subItem.label}</a>
                                                ) : (
                                                    <Link className="dropdown-item" to={subItem.href}>{subItem.label}</Link>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ) : (
                                <li key={index} className="nav-item p-2">
                                    {item[1].includes("#") ? (
                                        <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" href={item[1]}>{item[0]}</a>
                                    ) : (
                                        <Link className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" to={item[1]}>{item[0]}</Link>
                                    )}
                                </li>
                            )
                        )}
                    </ul>
                    <ContactInfo />
                </div>
                <div className="d-flex flex-column flex-sm-row justify-content-between pt-4 mt-4 border-top">
                    <p>© 2024 DocInJob. All rights reserved.</p>
                    <ul className="list-unstyled d-flex">
                        <li className="ms-3"><Link className="link-body-emphasis text-decoration-none" to="/terms-and-conditions">Terms & Conditions</Link></li>
                        <li className="ms-3"><Link className="link-body-emphasis text-decoration-none" to="/privacy-policy">Privacy Policy</Link></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Footer;