import { Link } from "react-router-dom";
import Banner from "../components/Banner";
import { useEffect } from "react";

const Home = ({ setCityIndex, cities = [], specialities=[], cityIndex = 0 }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'DocInJob | Home';
    }, []);

    return (
        <div>
            <Banner setCityIndex={setCityIndex} cities={cities} specialities={specialities} cityIndex={cityIndex} image="assets/Banner-1.jpg" />
            <div className="container col-xxl-10 px-4 py-4">
                <div className="row g-0 flex-wrap gap-5">
                    <div id="lab-test" className="col-12 col-md row g-0 flex-wrap justify-content-center gap-4 gap-lg-0 px-0 px-sm-5 px-md-0">
                        <div className="col-4 col-md-8 col-xxl-4 d-flex justify-content-md-center justify-content-xxl-start">
                            <img style={{ width: 100 + "%", height: "auto" }} alt="book lab test" src="assets/book-lab-test-icon.png" />
                        </div>
                        <div className="col col-md-12 col-xxl text-end text-md-center text-xxl-end d-flex flex-column align-items-end align-items-md-center align-items-xxl-end justify-content-between">
                            <h4 className="fw-bold text-accent display-6">Book Lab Test</h4>
                            <button className="btn btn-primary fw-bold" onClick={() => window.location.href = "/lab-test"}>Diagnostic Center</button>
                        </div>
                    </div>
                    <div id="medicine" className="col-12 col-md row g-0 flex-wrap justify-content-center gap-4 gap-lg-0 px-0 px-sm-5 px-md-0">
                        <div className="col-4 col-md-8 col-xxl-4 d-flex justify-content-md-center justify-content-xxl-start">
                            <img style={{ width: 100 + "%", height: "auto" }} alt="buy medicine" src="assets/buy-medicine-icon.png" />
                        </div>
                        <div className="col col-md-12 col-xxl text-end text-md-center text-xxl-end d-flex flex-column align-items-end align-items-md-center align-items-xxl-end justify-content-between">
                            <h4 className="fw-bold text-accent display-6">Buy Medicine</h4>
                            <button className="btn btn-primary fw-bold" onClick={() => window.location.href = "/medicine"}>Buy Now</button>
                        </div>
                    </div>
                    <div id="surgery" className="col-12 col-md row g-0 flex-wrap justify-content-center gap-4 gap-lg-0 px-0 px-sm-5 px-md-0">
                        <div className="col-4 col-md-8 col-xxl-4 d-flex justify-content-md-center justify-content-xxl-start">
                            <img style={{ width: 100 + "%", height: "auto" }} alt="surgery assistance" src="assets/surgery-assistance-icon.png" />
                        </div>
                        <div className="col col-md-12 col-xxl text-end text-md-center text-xxl-end d-flex flex-column align-items-end align-items-md-center align-items-xxl-end justify-content-between">
                            <h4 className="fw-bold text-accent display-6">Book Surgery</h4>
                            <button className="btn btn-primary fw-bold" onClick={() => window.location.href = "/surgery"}>Consult Now</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container col-xxl-10 px-4 py-4">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div className="col-10 col-sm-8 col-lg-6">
                        <img src="assets/doctor-image.png" className="d-block mx-lg-auto img-fluid" alt="Working Together for Your Healthier Life" width="700" height="500" loading="lazy" />
                    </div>
                    <div className="col-lg-6">
                        <h1 className="display-5 fw-bold text-primary lh-1 mb-3">Working Together for Your Healthier Life!</h1>
                        <p className="lead">Quickly design and customize responsive mobile-first sites with Bootstrap, the world’s most popular front-end open source toolkit, featuring Sass variables and mixins, responsive grid system, extensive prebuilt components, and powerful JavaScript plugins.</p>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            <Link to="/about" className="btn btn-primary btn-lg px-4 me-md-2">Know More</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container col-xxl-10 px-4 py-4">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div className="col-lg-6">
                        <h1 className="display-5 fw-bold text-primary lh-1 mb-3">Download Doc<span className="text-accent">In</span>Job App</h1>
                        <p className="lead">Quickly design and customize responsive mobile-first sites with Bootstrap, the world’s most popular front-end open source toolkit, featuring Sass variables and mixins, responsive grid system, extensive prebuilt components, and powerful JavaScript plugins.</p>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            <button type="button" className="btn btn-primary btn-lg px-4 me-md-2" disabled={true}>Comming Soon</button>
                        </div>
                    </div>
                    <div className="col-10 col-sm-8 col-lg-6">
                        <img src="assets/download-image.png" className="d-block mx-lg-auto img-fluid" alt="DocInJob App" width="700" height="500" loading="lazy" />
                    </div>
                </div>
            </div>
            <div className="container col-xxl-10 px-4 py-4">
                <h1 className="text-center display-4 fw-bold text-primary">Dicover the Online Appointment!</h1>
                <h2 className="text-center">A step by step guide to book an on-demand appointment</h2>
                <div className="mt-5 mb-4 bg-primary d-flex flex-column flex-lg-row flex-wrap align-items-center justify-content-center text-center text-lg-start p-4">
                    <div className="col col-lg-9 d-flex flex-column flex-lg-row flex-wrap align-items-center align-items-lg-start justify-content-center text-center text-lg-start">
                        <div className="col col-lg-4 p-3">
                            <h3 className="display-6 mb-0 fw-bold text-uppercase text-white">Step 1</h3>
                            <h4 className="text-accent">Find a Doctor</h4>
                            <p className="text-white mb-0">With more than 1000+ doctors and on mission to provide best care Health Care Service</p>
                        </div>
                        <div className="col col-lg-4 p-3">
                            <h3 className="display-6 mb-0 fw-bold text-uppercase text-white">Step 2</h3>
                            <h4 className="text-accent">View Doctor</h4>
                            <p className="text-white mb-0">Share your health concem here and we shall assign you a top doctor</p>
                        </div>
                        <div className="col col-lg-4 p-3">
                            <h3 className="display-6 mb-0 fw-bold text-uppercase text-white">Step 3</h3>
                            <h4 className="text-accent">Book a Visit</h4>
                            <p className="text-white mb-0">Book your time slot with doctor from your comfort zone</p>
                        </div>
                    </div>
                    <div className="col col-lg-3 p-3">
                        <img style={{ maxWidth: 100 + "%" }} alt="appointment" src="assets/appointment-image.png" />
                    </div>
                </div>
            </div>
            <div id="last" className="container col-xxl-10 px-4 py-4">
                <h5 className='display-5 text-primary fw-bold text-center mb-5'>Search Doctors by Specialities</h5>
                <div className="mb-5 d-flex flex-wrap justify-content-center gap-5">
                    <Link to="/search/?speciality=urology" className="text-decoration-none d-flex flex-column align-items-center">
                        <div style={{ width: 150 + "px", height: 150 + "px" }} className="urolody-icon" />
                        <span className="fw-bold">Urology</span>
                    </Link>
                    <Link to="/search/?speciality=neurology" className="text-decoration-none d-flex flex-column align-items-center">
                        <div style={{ width: 150 + "px", height: 150 + "px" }} className="neurology-icon" />
                        <span className="fw-bold">Neurology</span>
                    </Link>
                    <Link to="/search/?speciality=orthopedic" className="text-decoration-none d-flex flex-column align-items-center">
                        <div style={{ width: 150 + "px", height: 150 + "px" }} className="orthopedic-icon" />
                        <span className="fw-bold">Orthopedic</span>
                    </Link>
                    <Link to="/search/?speciality=cardiologist" className="text-decoration-none d-flex flex-column align-items-center">
                        <div style={{ width: 150 + "px", height: 150 + "px" }} className="cardiologist-icon" />
                        <span className="fw-bold">Cardiologist</span>
                    </Link>
                    <Link to="/search/?speciality=dentist" className="text-decoration-none d-flex flex-column align-items-center">
                        <div style={{ width: 150 + "px", height: 150 + "px" }} className="dentist-icon" />
                        <span className="fw-bold">Dentist</span>
                    </Link>
                    <Link to="/search/?speciality=general%20physician" className="text-decoration-none d-flex flex-column align-items-center">
                        <div style={{ width: 150 + "px", height: 150 + "px" }} className="general-physician-icon" />
                        <span className="fw-bold">General Physician</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Home;