import Timings from "./Timings";


const DoctorTimings = ({ clinic, onChange }) => {
    return (
        <div className='d-flex flex-column gap-4'>
            {clinic.doctorList.length ?
                clinic.doctorList.map((doctor, index) => (
                    <div key={"doctor-timing-" + index} className='card' style={{ minWidth: 500 + "px" }}>
                        <div className="card-body">
                            <div className="d-flex flex-wrap align-items-stretch">
                                {doctor.doctorId.image ? (
                                    <img src={doctor.doctorId.image} style={{ minWidth: 300 + "px" }} alt="avatar" />
                                ) : (
                                    <></>
                                )}
                                <div>
                                    <h5 className="card-title">{doctor.doctorId.gender} {doctor.doctorId.name}</h5>
                                    <h6 className="card-subtitle mb-2 text-body-secondary">{doctor.doctorId.speciality} | {doctor.doctorId.qualification} | {doctor.doctorId.experience} Years Experience</h6>
                                    <h4>Rs. {doctor.doctorId.fee}</h4>
                                    <p>Phone: {doctor.doctorId.phone}</p>
                                    <p>Email: {doctor.doctorId.email}</p>
                                </div>
                            </div>
                            <Timings doctor={doctor.doctorId} clinic={clinic} timings={doctor.timing} onChange={onChange} />
                        </div>
                    </div>
                )) : (
                    <div>No doctors!</div>
                )
            }
        </div>
    );
}

export default DoctorTimings;