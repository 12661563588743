import React, { useState, useEffect, useCallback } from 'react';
import { GetAppointments } from '../api/api';
import AppointmentCard from './AppointmentCard';

const Appointments = () => {
    const [appointments, setAppointments] = useState([]);
    const [newAppointments, setNewAppointments] = useState([])
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [shown, setShown] = useState(0);
    const [total, setTotal] = useState(0);

    const getAppointments = useCallback(async () => {
        var response = await GetAppointments({ data: { page: page } });
        if (page !== response.currentPage)
            setPage(response.currentPage);
        setNewAppointments(response.results);
        setPages(response.totalPages);
        setTotal(response.totalResults);
    }, [page]);

    useEffect(() => {
        if (newAppointments) {
            var totalAppointments = []
            if (page === 1)
                totalAppointments = newAppointments;
            else
                totalAppointments = appointments.concat(newAppointments)
            setNewAppointments(null);
            setAppointments(totalAppointments);
        }
        setShown(appointments.length);
    }, [appointments, newAppointments, page]);

    useEffect(() => {
        getAppointments();
    }, [getAppointments]);


    return (
        <div>
            <div className='text-body-secondary mt-0 mb-3'>Showing {shown} of {total} Appointments</div>
            <div className='d-flex flex-wrap align-items-stretch gap-2'>
                {appointments.length ?
                    appointments.map((appointment, index) => (
                        <AppointmentCard key={`appointment-${index}`} appointment={appointment} />
                    )) : (
                        <div>You have no appointments!</div>
                    )
                }
                {
                    page < pages ? (
                        <button className='btn btn-primary' onClick={getAppointments}>Load More</button>
                    ) : null
                }
            </div>
        </div>
    );
}

export default Appointments;