import React, { useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetOtp, Login } from '../api/api';

const LoginForm = ({onLogin=null}) => {
    const [searchParams] = useSearchParams();
    const [phone, setPhone] = useState('');
    const [otp, setOTP] = useState('');
    const [submitText, setSubmitText] = useState('SIGN IN | SIGN UP')
    const [submitDisabled, setSubmitDisabled] = useState(false)
    
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (phone.trim() && otp.trim()) {
            setSubmitText('Logging in...');
            setSubmitDisabled(true);
            try {
                await Login({phone: "+91" + phone, otp: otp});
                if (onLogin != null)
                    onLogin();
                if (searchParams.get("redirect"))
                    navigate(searchParams.get("redirect"));
            } catch (error) {
                console.error('Error in Login:', error.response ? error.response.data : error.message);
                setSubmitText('SIGN IN | SIGN UP');
                setSubmitDisabled(false);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{minWidth: 200 + 'px'}}>
            <div className="input-group mb-2">
                <div className="input-group-text">
                    +91
                </div>
                <div className="form-floating">
                    <input
                        type="tel"
                        id="login-phone-input"
                        className="form-control"
                        aria-describedby="phoneHelp"
                        value={phone}
                        placeholder="Phone Number"
                        required={true}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <label className="form-label">Phone Number</label>
                </div>
                <button className="btn btn-outline-accent" type="button" id="login-phone-get-otp-button" onClick={() => GetOtp({phone: `+91${phone}`})}>Get OTP</button>
            </div>
            <div className="form-floating mb-2">
                <input
                    type="number"
                    id="login-phone-otp-input"
                    className="form-control"
                    value={otp}
                    placeholder="OTP"
                    required={true}
                    onChange={(e) => setOTP(e.target.value)}
                />
                <label className="form-label">OTP</label>
            </div>
            <button type="submit" className="btn btn-lg btn-primary w-100" disabled={submitDisabled}>{submitText}</button>
        </form>
    );
}

export default LoginForm;