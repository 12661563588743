import React, { useState } from 'react';
import { UpdateUser } from '../api/api';

const UserForm = ({ user, getUser }) => {
    const [name, setName] = useState(user.name);
    const [age, setAge] = useState(user.age);
    const [gender, setGender] = useState(user.gender);
    const [address, setAddress] = useState(user.address);
    const [submitText, setSubmitText] = useState('Save')
    const [submitDisabled, setSubmitDisabled] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name.trim() && age) {
            setSubmitText('Saving...');
            setSubmitDisabled(true);
            try {
                await UpdateUser({ name: name, age: age, gender: gender, address: address });
                if (getUser != null)
                    getUser();
            } catch (error) {
                console.error('Error Saving:', error.response ? error.response.data : error.message);
            }
            setSubmitText('Save');
            setSubmitDisabled(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{ minWidth: 300 + 'px' }}>
            <div className='d-flex flex-wrap gap-2'>
                <div className="col-12 col-sm-6 form-floating mb-2">
                    <input
                        type="text"
                        id="user-name-input"
                        className="form-control"
                        aria-describedby="nameHelp"
                        value={name}
                        placeholder="Name"
                        required={true}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <label className="form-label">Name</label>
                </div>
                <div className="col-6 col-sm-3 form-floating mb-2">
                    <input
                        type="number"
                        id="user-age-input"
                        className="form-control"
                        value={age}
                        placeholder="Age"
                        required={true}
                        onChange={(e) => setAge(e.target.value)}
                    />
                    <label className="form-label">Age</label>
                </div>
                <div className="col form-floating mb-2">
                    <select className="form-select" id="user-gender-select" defaultValue={gender} required={true} onChange={(e) => setGender(e.target.value)}>
                        <option value="" disabled>Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                    <label className="form-label">Gender</label>
                </div>
            </div>
            <div className="form-floating mb-2">
                <textarea
                    id="user-address-input"
                    className="form-control"
                    value={address}
                    placeholder="Address"
                    rows="10"
                    onChange={(e) => setAddress(e.target.value)}
                />
                <label className="form-label">Address</label>
            </div>
            <button type="submit" className="btn btn-lg btn-primary w-100" disabled={submitDisabled}>{submitText}</button>
        </form>
    );
}

export default UserForm;