import React, { useState, useEffect, useCallback } from 'react';
import { GetDoctors } from '../api/api';
import DoctorCard from '../components/DoctorCard';
import DoctorForm from '../components/DoctorForm';
import Modal from '../components/Modal';

const AdminDoctors = () => {
    const [doctors, setDoctors] = useState([])
    const [newDoctors, setNewDoctors] = useState([])
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [shown, setShown] = useState(0);
    const [total, setTotal] = useState(0);


    const getDoctors = useCallback(async () => {
        var response = await GetDoctors({ data: { page: page } });
        if (page !== response.currentPage)
            setPage(response.currentPage);
        setNewDoctors(response.results);
        setPages(response.totalPages);
        setTotal(response.totalResults);
    }, [page]);

    useEffect(() => {
        if (newDoctors) {
            var totalDoctors = []
            if (page === 1)
                totalDoctors = newDoctors;
            else 
                totalDoctors = doctors.concat(newDoctors)
            setNewDoctors(null);
            setDoctors(totalDoctors);
        }
        setShown(doctors.length);
    }, [doctors, newDoctors, page]);

    useEffect(() => {
        getDoctors();
        document.title = 'DocInJob | Doctors';
    }, [getDoctors]);

    return (
        <div className='d-flex flex-column p-3 p-sm-5 gap-2'>
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    <h3 className='mb-0'>Doctors</h3>
                    <div className='text-body-secondary'>Showing {shown} of {total} Doctors</div>
                </div>
                <Modal modalId="add-doctor" title="Add Doctor" body={<DoctorForm onSubmit={() => {
                    document.getElementById(`add-doctor-close`).click();
                    getDoctors(1);
                }} />}
                    buttonClassName="btn btn-lg btn-outline-primary"
                    buttonContent={
                        <span className="d-flex align-items-center gap-2">
                            <i className="bi bi-plus-circle lh-1"></i>
                            <span className="lh-1">Add Doctor</span>
                        </span>
                    } />
            </div>
            <div className='d-flex flex-wrap gap-2'>
                {doctors.length ?
                    doctors.map((doctor, index) => (
                        <DoctorCard key={`doctor-${index}`} onChange={() => getDoctors(1)} doctor={doctor}
                            form={<DoctorForm doctor={doctor} onSubmit={() => {
                                document.getElementById(`edit-doctor-${doctor._id}-close`).click();
                                getDoctors(1);
                            }} />}
                        />
                    )) : (
                        <div>No doctors!</div>
                    )
                }
            </div>
            {page < pages ? (
                <button className="btn btn-primary w-100" onClick={() => setPage(page + 1)}>Load More</button>
            ) : (
                <></>
            )}
        </div>
    );
}

export default AdminDoctors;