const ContactButton = () => {
    return (
        <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" target="_blank" rel="noreferrer" href="tel:+918822882288">
            <div className="d-flex align-items-center gap-2">
                <div className="bg-primary px-2 py-1 rounded rounded-circle">
                    <i className="bi bi-telephone lh-1 text-white"></i>
                </div>
                <div className="fw-bold text-primary">
                    CONTACT: +91 88228 82288
                </div>
            </div>
        </a>
    );
}

export default ContactButton;