import { Link } from "react-router-dom";
import { Logout } from '../api/api';
import Logo from './Logo';
import ContactButton from "./ContactButton";

const Navbar = ({ getUser, items = [], user = null, adminUser = null, logout = false, login = true }) => {
    return (
        <nav className="navbar navbar-expand-lg bg-body px-4" style={{ height: 7 + "rem" }}>
            <div className="container-fluid">
                <Link onClick={() => document.getElementById('close-nav').click()} className="navbar-brand fw-bold" to="/"><Logo /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="offcanvas offcanvas-end w-100" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbarLabel"><Link onClick={() => document.getElementById('close-nav').click()} className="navbar-brand fw-bold" to="/"><Logo /></Link></h5>
                        <button id='close-nav' type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <div className="h-100 ms-auto d-flex flex-column-reverse flex-lg-column align-items-stretch justify-content-between">
                            <ul className="navbar-nav flex-column-reverse flex-lg-row align-items-center justify-content-end my-2 my-lg-0">
                                <li className="nav-item p-2">
                                    <ContactButton />
                                </li>
                                {user == null && adminUser == null && login ? (
                                    <li className="nav-item">
                                        <Link onClick={() => document.getElementById('close-nav').click()} className="btn btn-primary" to="/user">SIGN IN | SIGN UP</Link>
                                    </li>
                                ) : user != null ? (
                                    <li className="nav-item p-2 dropdown">
                                        <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover dropdown-toggle d-flex align-items-center justify-content-center text-primary gap-2" href={"#user-menu"} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="bi bi-person-circle fs-3"></i>
                                            <div>{user.name ? user.name : user.phone}</div>
                                        </a>
                                        <ul id="user-menu" className="dropdown-menu dropdown-menu-end">
                                            <li>
                                                <Link onClick={() => document.getElementById('close-nav').click()} className="dropdown-item" to="/user">Profile</Link>
                                            </li>
                                            <li>
                                                <Link onClick={() => document.getElementById('close-nav').click()} className="dropdown-item" to="/user#appointments">Appointments</Link>
                                            </li>
                                            <li>
                                                <button className="dropdown-item text-danger" onClick={() => { Logout(); getUser() }}>Logout</button>
                                            </li>
                                        </ul>
                                    </li>
                                ) : adminUser != null ? (
                                    <li className="nav-item p-2">
                                        <Link onClick={() => document.getElementById('close-nav').click()} className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" to="/admin">{adminUser.adminId}</Link>
                                    </li>
                                ) : (
                                    <></>
                                )}
                            </ul>
                            <ul className="navbar-nav text-center my-2 my-lg-0 gap-2">
                                {items.map((item, index) =>
                                    Array.isArray(item[1]) ? (
                                        <li key={index} className="nav-item p-2 dropdown">
                                            <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover dropdown-toggle" href={"#" + item[0] + "menu"} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                {item[0]}
                                            </a>
                                            <ul id={item[0] + "menu"} className="dropdown-menu">
                                                {item[1].map((subItem, subIndex) => (
                                                    <li key={subIndex}>
                                                        <Link onClick={() => document.getElementById('close-nav').click()} className="dropdown-item" to={subItem.href}>{subItem.label}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ) : (
                                        <li key={index} className="nav-item p-2">
                                            <Link onClick={() => document.getElementById('close-nav').click()} className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" to={item[1]}>{item[0]}</Link>
                                        </li>
                                    )
                                )}
                            </ul>
                            <div></div>
                        </div>
                    </div>
                </div>

            </div>
        </nav>
    );
}

export default Navbar;
